import axios from 'axios'
import React, { useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { ipaddress } from '../App'
import { Modal } from 'react-bootstrap'
import Svg1 from '../Svgs/Svg1'
import Svg2 from '../Svgs/Svg2'

const Signuppage = () => {
    const navigate=useNavigate()

    const[email,setemail]=useState('')
    const[password,setpassword]=useState('')
    const[username,setusername]=useState('')
    const[contact_number,setcontact_number]=useState('')
    const[college,setcollege]=useState('')
    const[qualification,setqualification]=useState('')
    const[yop,setyop]=useState('')
    const[location,setlocation]=useState('')


    const[load,setload]=useState(false)
    const[load2,setload2]=useState(false)
    const[type,settype]=useState(false)

    const login=()=>{
      setload2(true)
      setTimeout(() => {
        send()
      }, 1000);
    }

    const send=()=>{
     if(enteredotp.join('')==backendotp){
      axios.post(`${ipaddress}/students/`,{
        email:email,
        password:password,
        username:username,
        contact:contact_number,
        qualification:qualification,
        year_of_passout:Number(yop),
        college:college,
        location:location
      })
      .then((r)=>{
        console.log("Successs",r.data)
        toast.success('Successfully Registered',{
            autoClose:1000,
            theme:'colored',
            position:'top-center'
        })
        setTimeout(() => {
            move()
        }, 2000);
        setload2(false)
      })
      .catch((err)=>{
        console.log("Err",err)
        setload2(false)
      })
     }
     else{
      setload2(false)
      toast.error('Invalid OTP',{
        autoClose:1000,
        theme:'colored',
        position:'top-center'
      })
     }
    }

    const move=()=>{
        navigate('/loginpage')
    }

    const[show,setshow]=useState(false)
    const[backendotp,setbackendotp]=useState()
    const[otp,setotp]=useState()

    const get_otp=()=>{
      setload(true)
      axios.post(`${ipaddress}/VerifyEmail/`,{
        email:email
      })
      .then((r)=>{
        setshow(true)
        console.log(r.data);
        setbackendotp(r.data.otp)
        setload(false)
      })
    }

    const[show2,setshow2]=useState(false)
    const[show3,setshow3]=useState(false)
    const[new_password,setnew_password]=useState('')
    const[pass_otp,setpass_otp]=useState('')

    const forgot_password=()=>{
      setload2(true)
      axios.post(`${ipaddress}/password-reset/`,{
        email:email
      })
      .then((r)=>{
        toast.success('OTP sent to Mail',{
          autoClose:2000,
          theme:'colored',
          position:'top-center'
        })
        setload2(false)
        setshow2(false)
        setshow3(true)
      })
      .catch((err)=>{
        toast.warn('Email does not exist',{
          autoClose:2000,
          theme:'colored',
          position:'top-center'
        })
        setload2(false)
      })
    }

    const update_password=()=>{
      setload2(true)
      axios.post(`${ipaddress}/verify-otp/`,{
        email:email,
        otp:pass_otp,
        new_password:new_password
      })
      .then((r)=>{
        setload2(false)
        toast.success('Password updated!',{
          autoClose:1000,
          theme:'colored',
          position:'top-center'
        })
        setshow3(false)
        setTimeout(() => {
          navigate('/loginpage')
        }, 2000);
      })
      .catch((err)=>{
        setload2(false)
        toast.error('Invalid OTP!',{
          autoClose:1000,
          theme:'colored',
          position:'top-center'
        })
      })
    }


    const inputRefs = useRef([]);

    const [enteredotp, setEnteredotp] = useState(new Array(6).fill(''));

    const handleChange = (e, index) => {
      const value = e.target.value;

      if (/^\d$/.test(value)) {  // Allow only digits
        const newOtp = [...enteredotp];
        newOtp[index] = value;
        setEnteredotp(newOtp);
      if (value.length === 1 && index < 5) {
        inputRefs.current[index + 1].focus();
      } else if (value.length === 0 && index > 0) {
        inputRefs.current[index - 1].focus();
      }
    }
    else {
      e.target.value = ''; // Clear the input if the value is not a digit
    }
    };
  
    const handleKeyDown = (e, index) => {
      if (e.key === 'Backspace' && !e.target.value && index > 0) {
        inputRefs.current[index - 1].focus();
      }
    };

    const handlePaste = (e) => {
      const pasteData = e.clipboardData.getData('text').slice(0, 6);
      pasteData.split('').forEach((char, index) => {
        inputRefs.current[index].value = char;
        if (index < 5) {
          inputRefs.current[index + 1].focus();
        }
      });
      e.preventDefault(); // Prevent the default paste behavior
    };

return (
    <div className='animate__animated animate__fadeIn'>
        <div className="m-0 row" style={{height:'100vh'}}>
            <div className="main-div col-md-4 d-flex align-items-center justify-content-center">
                <div>
                    <p className='text-white fw-bolder m-0 text-center' style={{fontSize:'40px'}}>Merida Tech</p>
                    <p className='text-white' style={{fontSize:'17px'}}>The most popular peer to peer leading at SEA</p>
                    {/* <button className='btn rounded-pill px-3 text-white' style={{backgroundColor:'#0575E6',fontSize:'13px'}}>Read More</button> */}
                </div>
            </div>
            <div className="col-md-8 px-md-5 d-flex flex-column justify-content-center">
                <div className='py-4'>
                <img src={require('../images/SLA_logo_Blue 1.png')} className='d-block mx-auto' alt="" />
                </div>

                <div className='mt-2 row m-0'>
                <div className='col-lg-6 pe-md-4'>
                  <label htmlFor="" className='mb-3 signup-label'>Name</label>
                    <div class="input-group mb-3 px-3 rounded-pill py-2 border border-secondary-subtle">
  <span class="input-group-text bg-transparent border-0" id="basic-addon1"><svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="gray" class="bi bi-person" viewBox="0 0 16 16">
  <path d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6m2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0m4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4m-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10s-3.516.68-4.168 1.332c-.678.678-.83 1.418-.832 1.664z"/>
</svg></span>
  <input type="text" value={username} onChange={(e)=>{
    setusername(e.target.value)
  }} class="form-control shadow-none bg-transparent border-0 signup-inputs" placeholder="Enter your name" aria-label="Username" aria-describedby="basic-addon1"/>
</div>
                    </div>

                    <div className='col-lg-6 ps-md-4'>
                  <label htmlFor="" className='mb-3 signup-label'>Email ID</label>
                    <div class="input-group mb-3 px-3 rounded-pill py-2 border border-secondary-subtle">
  <span class="input-group-text bg-transparent border-0" id="basic-addon1"><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="gray" class="bi bi-envelope" viewBox="0 0 16 16">
  <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1zm13 2.383-4.708 2.825L15 11.105zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741M1 11.105l4.708-2.897L1 5.383z"/>
</svg></span>
  <input type="text" value={email} onChange={(e)=>{
    setemail(e.target.value)
  }} class="form-control shadow-none bg-transparent border-0 signup-inputs" placeholder="Enter your email" aria-label="Username" aria-describedby="basic-addon1"/>
</div>
                    </div>

                    <div className='col-lg-6 pe-md-4'>
                  <label htmlFor="" className='my-3 signup-label'>Mobile No</label>
                    <div class="input-group mb-3 px-3 rounded-pill py-2 border border-secondary-subtle">
  <span class="input-group-text bg-transparent border-0" id="basic-addon1"><svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" fill="gray" class="bi bi-telephone" viewBox="0 0 16 16">
  <path d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.6 17.6 0 0 0 4.168 6.608 17.6 17.6 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.68.68 0 0 0-.58-.122l-2.19.547a1.75 1.75 0 0 1-1.657-.459L5.482 8.062a1.75 1.75 0 0 1-.46-1.657l.548-2.19a.68.68 0 0 0-.122-.58zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.68.68 0 0 0 .178.643l2.457 2.457a.68.68 0 0 0 .644.178l2.189-.547a1.75 1.75 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.6 18.6 0 0 1-7.01-4.42 18.6 18.6 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877z"/>
</svg></span>
  <input type="tel" maxLength={10} value={contact_number} onChange={(e)=>{
    setcontact_number(e.target.value)
  }} class="form-control shadow-none bg-transparent border-0 signup-inputs" placeholder="Enter your Contact Number" aria-label="Username" aria-describedby="basic-addon1"/>
</div>
                    </div>

                    <div className='col-lg-6 ps-md-4'>
                  <label htmlFor="" className='my-3 signup-label'>Password</label>
                    <div class="input-group mb-3 px-3 rounded-pill py-2 border border-secondary-subtle">
  <span class="input-group-text bg-transparent border-0" id="basic-addon1"><Svg1/></span>
  <input type={type ? 'text':'password'} value={password} onChange={(e)=>{
    setpassword(e.target.value)
  }} class="form-control shadow-none bg-transparent border-0 signup-inputs" placeholder="Enter the Password" aria-label="Username" aria-describedby="basic-addon1"/>
  <span onClick={()=>{
    settype(!type)
  }} style={{cursor:'pointer'}} class="input-group-text bg-transparent border-0" id="basic-addon2"><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="gray" class="bi bi-eye" viewBox="0 0 16 16">
  <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8M1.173 8a13 13 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5s3.879 1.168 5.168 2.457A13 13 0 0 1 14.828 8q-.086.13-.195.288c-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5s-3.879-1.168-5.168-2.457A13 13 0 0 1 1.172 8z"/>
  <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5M4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0"/>
</svg></span>
</div>
                    </div>

                    <div className='col-lg-6 pe-md-4'>
                  <label htmlFor="" className='my-3 signup-label'>College Name</label>
                    <div class="input-group mb-3 px-3 rounded-pill py-2 border border-secondary-subtle">
  <span class="input-group-text bg-transparent border-0" id="basic-addon1"><img src={require('../images/_x32_5_School.png')} width={24} alt="" /></span>
  <input type="text" value={college} onChange={(e)=>{
    setcollege(e.target.value)
  }} class="form-control shadow-none bg-transparent border-0 signup-inputs" placeholder="Enter your College Name" aria-label="Username" aria-describedby="basic-addon1"/>
</div>
                    </div>

                    <div className='col-lg-6 ps-md-4'>
                  <label htmlFor="" className='my-3 signup-label'>Qualification</label>
                    <div class="input-group mb-3 px-3 rounded-pill py-2 border border-secondary-subtle">
  <span class="input-group-text bg-transparent border-0" id="basic-addon1"><img src={require('../images/image 13.png')} width={24} alt="" /></span>
  <input type="text" value={qualification} onChange={(e)=>{
    setqualification(e.target.value)
  }} class="form-control shadow-none bg-transparent border-0 signup-inputs" placeholder="Enter your Highest qualification" aria-label="Username" aria-describedby="basic-addon1"/>
</div>
                    </div>

                    <div className='col-lg-6 pe-md-4'>
                  <label htmlFor="" className='my-3 signup-label'>Year of Pass out</label>
                    <div class="input-group mb-3 px-3 rounded-pill py-2 border border-secondary-subtle">
  <span class="input-group-text bg-transparent border-0" id="basic-addon1"><img src={require('../images/image 14.png')} width={24} alt="" /></span>
  <input type="text" value={yop} onChange={(e)=>{
    setyop(e.target.value)
  }} class="form-control shadow-none bg-transparent border-0 signup-inputs" placeholder="Enter your Pass out year" aria-label="Username" aria-describedby="basic-addon1"/>
</div>
                    </div>

                    <div className='col-lg-6 ps-md-4'>
                  <label htmlFor="" className='my-3 signup-label'>Location</label>
                    <div class="input-group mb-3 px-3 rounded-pill py-2 border border-secondary-subtle">
  <span class="input-group-text bg-transparent border-0" id="basic-addon1"><Svg2/></span>
  <input type="text" value={location} onChange={(e)=>{
    setlocation(e.target.value)
  }} class="form-control shadow-none bg-transparent border-0 signup-inputs" placeholder="Enter your location" aria-label="Username" aria-describedby="basic-addon1"/>
</div>
                    </div>

                    <div className='col-md-6 mx-auto mt-3'>
                        <button className='btn rounded w-100 text-white mt-2 px-5' onClick={()=>{
                            // navigate('/mainpage')
                            if(password.length<8){
                              toast.warn('Password should contain minimum 8 characters',{
                                autoClose:2000,
                                theme:'colored',
                                position:'top-center'
                              })
                            }
                            else{
                            get_otp()
                            }
                        }} style={{backgroundColor:'#0575E6',height:'50px'}}>
                          {/* <span class={`spinner-border spinner-border-sm ${load ? '':'d-none'}`} aria-hidden="true"></span>
  <span className={`${load ? 'ms-2':'d-none'}`} role="status">Loading...</span>
  <span className={`${load ? 'd-none':''}`}>Signup</span> */}
  <span class={`spinner-grow spinner-grow-sm ${load ? '':'d-none'}`} aria-hidden="true"></span>
  <span className={load ? 'ms-2':'d-none'} role="status">Sending...</span>
  <span className={load ? 'd-none':'fw-medium'} style={{letterSpacing:'1px'}}>GET OTP</span>

                        </button>
                    </div>
                    <p className='text-center text-dark mt-2' style={{cursor:'pointer',fontSize:'14px'}} onClick={()=>{
                    navigate('/loginpage')
                    }}>Already have an account? <span className='ms-2' style={{color:'#007AFF'}}>Login Now</span></p>
                </div>
            </div>
        </div>

{/* OTP Modal */}
<div className={`row m-0 animate__animated animate__fadeIn ${show ? '':'d-none'}`} style={{position:'fixed',top:0,left:0,width:'100%',height:'100vh',backgroundColor:'rgba(0,0,0,0.6)'}}>
<div className="col-lg-4 mx-auto p-5">
  <div className='bg-white' style={{borderRadius:'20px',padding:'30px'}}>
  <div>
            <img src={require('../images/Icon.png')} className='d-block mx-auto mb-4' alt="" />
            <label htmlFor="" className='d-block text-center fw-medium fw-bold fs-3' style={{color:'#444444'}}>Enter Your OTP</label>
            <p className='m-0 text-secondary text-center' style={{fontSize:'14px'}}>Kindly Check your mail id for the OTP</p>
            {/* <input type="number" value={otp} onChange={(e)=>{
              setotp(e.target.value)
            }} className='form-control py-3 bg-light border shadow-none mt-2' style={{border:'2px solid white'}} /> */}
            
            <div className="row mt-4">
            {[...Array(6)].map((_, index) => (
        <div className="col-2 px-2">
          <input
          key={index}
          type="text"
          maxLength="1"
          className='form-control bg-secondary-subtle shadow-none border-0 shadow-sm py-3'
          ref={(el) => inputRefs.current[index] = el}
          onChange={(e) => handleChange(e, index)}
          onKeyDown={(e) => handleKeyDown(e, index)}
          onPaste={handlePaste}
          style={{ textAlign: 'center', fontSize: '17px' }}
        />
        </div>
      ))}
            </div>
            <div className='mt-5 col-lg-8 mx-auto'>
              <button className='btn text-white w-100 btn-sm py-2' style={{backgroundColor:'#185CFF',borderRadius:'10px',height:'50px'}} onClick={()=>{
                
                  login()
                  // console.log(enteredotp.join(''))
              }}><span class={`spinner-grow spinner-grow-sm ${load2 ? '':'d-none'}`} aria-hidden="true"></span>
              <span className={load2 ? 'ms-2':'d-none'} style={{fontSize:'15px',letterSpacing:'1px'}} role="status">Submitting...</span>
              <span className={load2 ? 'd-none':'fw-medium'} style={{fontSize:'17px',letterSpacing:'1px'}}>Submit OTP</span></button>
              <button className='btn border-0 px-4 py-2 btn-sm fw-medium d-block mx-auto' style={{color:'#185CFF',fontSize:'16px'}} onClick={()=>{
                setshow(false)
    setEnteredotp(new Array(6).fill(''));
                
              }}>Close</button>
            </div>
          </div>
  </div>
</div>
</div>

    </div>
  )
}

export default Signuppage