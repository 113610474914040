import React, { useState } from 'react'
import 'react-pdf/dist/Page/AnnotationLayer.css';
import 'react-pdf/dist/Page/TextLayer.css';
import Loginpage from './Pages/Loginpage'
import './main.css'
import Main_page from './Pages/Main_page'
import Subpage from './Pages/Subpage'
import Take_test from './Pages/Take_test'
import Homepage from './Pages/Homepage'
import ScrollToTop from './Pages/Scroll_to_top'
import About_us from './Pages/About_us'
import Services from './Pages/Services'
import Protect from './Pages/Protect'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import Coding_test from './Pages/Coding_test'
import Success from './Pages/Success'
import { BrowserRouter,Routes,Route } from 'react-router-dom'
import Signuppage from './Pages/Signuppage'
import Protect2 from './Pages/Protect2'
import Admin_dashboard from './Admin/Admin_dashboard'
import Add_details from './Admin/Add_details'
import Create_questions from './Admin/Create_questions'
import View_technical_akills from './Admin/View_technical_akills'
import View_modules from './Admin/View_modules'
import Questions from './Admin/View_questions'
import Questionsets from './Admin/View_questionsets'
import Students from './Admin/Students'
import View_student from './Admin/View_student'
import Main_project_page from './Pages/Projects'
import Project_dashboard from './Projects/Project_dashboard'
import Admin_Loginpage from './Admin/Admin_login'
import View_category from './Projects/View_category'
import Categories from './Pages/Categories'
import View_attended_questions from './Admin/View_attended_questions'
import View_atteneded_coding_question from './Admin/View_atteneded_coding_question'
import { pdfjs } from 'react-pdf';
import Receipt_page from './Events/Receipt';
import Sharable_Invoice_page from './Events/Invoice';

export const ipaddress="https://onlinetest.meridahr.com"
// export const ipaddress="http://192.168.18.11:8000"
export const ipaddress2="https://onlinetest.meridahr.com"
// export const ipaddress2="http://192.168.18.11:9000"


const App = () => {
  pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

  const[score,setscore]=useState({})
  const[event_length,setevent_length]=useState(0)

  return (
    <div>
        <BrowserRouter>
        <ToastContainer/>
        <ScrollToTop/>
        <Routes>
            <Route path='/' element={<Homepage/>}/>
            <Route path='/about-us' element={<About_us/>}/>
            <Route path='/services' element={<Services/>}/>
            <Route path='/loginpage' element={<Loginpage/>}/>
            <Route path='/signuppage' element={<Signuppage/>}/>
            <Route path='/all-tests' element={<Protect Child={Categories}/>}/>
            <Route path='/:cat_name/:id' element={<Main_page/>}/>
            <Route path='/:cat_name/:subcat_name/:id' element={<Subpage/>}/>
            <Route path='/project_categories' element={<Main_project_page/>}/>
            <Route path='/:cat_name/:subcat_name/:test/mcq_test/:id' element={<Take_test set_score={setscore}/>}/>
            <Route path='/:cat_name/:subcat_name/:test/coding_test/:id' element={<Coding_test/>}/>
            <Route path='/success' element={<Success score={score} setscore={setscore}/>}/>
            <Route path='/invoice/:id/' element={<Sharable_Invoice_page/>}/>
            <Route path='/receipt/:id/' element={<Receipt_page/>}/>

            {/* Admin */}
            <Route path='/admin_login' element={<Admin_Loginpage/>}/>
            <Route path='/admin_dashboard' element={<Protect2 Child={Admin_dashboard}/>}/>
            <Route path='/add_details' element={<Add_details/>}/>
            <Route path='/create_questions' element={<Create_questions/>}/>
            <Route path='/technical_skills' element={<View_technical_akills/>}/>
            <Route path='/view_modules/:id' element={<View_modules/>}/>
            <Route path='/view_student/:id' element={<View_student/>}/>
            <Route path='/questions/:skill_id/:id' element={<Questions/>}/>
            <Route path='/question_sets' element={<Questionsets/>}/>
            <Route path='/students' element={<Students/>}/>
            <Route path='/view_test/:id' element={<View_attended_questions/>}/>
            <Route path='/evaluate_test/:id' element={<View_atteneded_coding_question/>}/>
            

            {/* Project */}
            <Route path='/projects' element={<Project_dashboard/>}/>
            <Route path='/view_category/:category_id' element={<View_category/>}/>
        </Routes>
        </BrowserRouter>
    </div>
  )
}

export default App