import React from 'react'
import { useNavigate } from 'react-router-dom'

const Main_navbar = ({value}) => {
  const navigate=useNavigate()

  return (
    <div>
      <div className='d-none d-lg-block' style={{position:'fixed',width:'100%',top:0,left:0,backgroundColor:'#ffff',zIndex:3}}>
        <div className='row m-0 py-3 container mx-auto'>
            <div className="col-6 d-flex align-items-center">
                <img src={require('../images/Logo_Blue 1 (1).png')} width={150} alt="" />
            </div>
            <div className="col-6 d-flex align-items-center justify-content-between">
                <span className={`fw-medium ${value==='home' ? 'text-decoration-underline text-primary':''}`} style={{fontSize:'14px',cursor:'pointer'}} onClick={()=>{
                  navigate('/')
                }}>Home</span>
                <span className={`fw-medium ${value==='services' ? 'text-decoration-underline text-primary':''}`} style={{fontSize:'14px',cursor:'pointer'}} onClick={()=>{
                  navigate('/services')
                }}>Our Services</span>
                <span className={`fw-medium ${value==='about' ? 'text-decoration-underline text-primary':''}`} style={{fontSize:'14px',cursor:'pointer'}} onClick={()=>{
                  navigate('/about-us')
                }}>About Us</span>
                <button className='btn text-white px-4 btn-sm' style={{backgroundColor:'#185CFF',fontSize:'13px'}}>Contact Us</button>
            </div>
        </div>
    </div>
    </div>
  )
}

export default Main_navbar