import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { ipaddress } from '../App';
import { toast } from 'react-toastify';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import Sidebar from './Sidebar';
import Navbar from './Navbar';
import Mcq_excel from './Mcq_excel';
import Coding_excel from './Coding_excel';

const Create_questions = () => {
  const renderTooltip3 = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Add Question
    </Tooltip>
  );

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip">
      {props}
    </Tooltip>
  );

  const[skills,setskills]=useState([])
  const[skill_id,setskill_id]=useState()
  const[categories,setcategories]=useState([])


  useEffect(()=>{
      axios.get(`${ipaddress}/technical-skills/`)
      .then((r)=>{
          console.log("skills",r.data);
          setskills(r.data)
      })

      axios.get(`${ipaddress}/question-categories/`)
      .then((r)=>{
          console.log("categories",r.data);
          setcategories(r.data)
      })
  },[])

  const[modules,setmodules]=useState([])
  const[module_id,setmodule_id]=useState()
  const[category,setcategory]=useState()

  const fetch_modules=(id)=>{
    axios.get(`${ipaddress}/technical-skills/${id}/modules/`)
    .then((r)=>{
        console.log("Modules",r.data);
        setmodules(r.data)
    })
  }

  const[q_marks,setq_marks]=useState(0)
  const[negative_marks,setnegative_marks]=useState(0)

  const [questions, setQuestions] = useState(
    {
      question_text: '',
      imgforquestion: '',
    },
  );

  const handleQuestionChange = (name,val) => {
   setQuestions((prev)=>({
    ...prev,
    [name]:val
   }))
  };

  const handleQuestionImageChange = (event) => {
    const file = event.target.files[0];
    setQuestions((prev)=>({
      ...prev,
      imgforquestion:file
    }))
    
  };

  const handleOptionChange = (qIndex, optIndex, field, event) => {
    const newQuestions = questions.map((q, index) => {
      if (index === qIndex) {
        const newOptions = q.options.map((opt, oIndex) => {
          if (oIndex === optIndex) {
            return {
              ...opt,
              [field]:
                field === 'is_correct' ? event.target.checked : event.target.value,
            };
          }
          return opt;
        });
        return { ...q, options: newOptions };
      }
      return q;
    });
    setQuestions(newQuestions);
  };

  const[q_id,setq_id]=useState()

  const add_question=()=>{
    const form=new FormData()
    form.append('question_text',questions.question_text)
    form.append('imageforquestion',questions.imgforquestion)
    form.append('marks',q_marks)
    form.append('negative_marks',negative_marks)

    axios.post(`${ipaddress}/MultipleMCQQuestionsView/?module=${Number(
          module_id
        )}&category=${Number(category)}`,form)

        .then((r)=>{
          console.log("Question added",r.data)
          setq_id(r.data.id)
          toast.success('Add options for the Question',{
            autoClose:2000,
            theme:'colored',
            position:'top-center'
          })

          setstate1(true)
        })
  }


//  Options

const [options, setOptions] = useState([{ option_text: '', imgforoption: '', marks: '',description:'',is_correct:false }]);

const handleInputChange = (index, field, value) => {
  const newOptions = [...options];
  newOptions[index][field] = value;
  setOptions(newOptions);
};

const handleCheckboxChange = (index, field, e) => {
  const newOptions = [...options];
  if(e.target.checked){
    newOptions[index][field] = true
  }
  else{
    newOptions[index][field] = false
  }
  setOptions(newOptions);
};

const handleAddOption = () => {
  setOptions([...options, { option_text: '', imgforoption: '', marks: '',description:'',is_correct:false }]);
};

const handleRemoveOption = (index) => {
  const newOptions = options.filter((_, i) => i !== index);
  setOptions(newOptions);
};

const handleImageUpload = (index, event) => {
  const file = event.target.files[0];
  const newOptions = [...options];
  newOptions[index].imgforoption = file;
  setOptions(newOptions);
};

const handleSubmit = async (e) => {
  e.preventDefault()
  const formData = new FormData();
  options.forEach((option, index) => {
    formData.append(`option_text_${index}`, option.option_text);
    formData.append(`marks_${index}`, option.marks);
    formData.append(`description_${index}`, option.description);
    formData.append(`is_correct_${index}`, option.is_correct);
    if (option.imgforoption) {
      formData.append(`imgforoption_${index}`, option.imgforoption);
    }
  });

  console.log("Options",options)
  formData.forEach((val,key)=>{
    console.log(`${key}`,`${val}`)
  })

  try {
    const response = await axios.post(`${ipaddress}/OptionCreationView/${q_id}/`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    console.log('Data sent successfully:', response.data);
    toast.success('Question added successfully!',{
      autoClose:2000,
      theme:'colored',
      position:'top-center'
    })
    setstate1(false)
    setQuestions({
      question_text: '',
      imgforquestion: '',
    })
    setq_marks(0)
    setnegative_marks(0)
    setOptions([{ option_text: '', imgforoption: '', marks: '',description:'' }])
  } catch (error) {
    console.error('Error sending data:', error);
  }
};


// Coding Questions
const [codingquestions, setcodingQuestions] = useState([{
    question_text: '',
    sample_input: '',
    sample_output: '',
    constraints:'',
    explanation: '',
    imgforquestion:'',
    fillintheblank:false
  }]);

  const handleAddcodingQuestion = () => {
    setcodingQuestions([...codingquestions, {
      question_text: '',
    sample_input: '',
    sample_output: '',
    constraints:'',
    explanation: '',
    imgforquestion:'',
    fillintheblank:false
    }]);
  };

  
const handleCodingImageUpload = (index, event) => {
  const file = event.target.files[0];
  const newOptions = [...codingquestions];
  codingquestions[index].imgforquestion = file;
  setcodingQuestions(newOptions);
};

  const handlecodingQuestionChange = (index, field, event) => {
    const newQuestions = codingquestions.map((q, qIndex) =>
      qIndex === index ? { ...q, [field]: event.target.value } : q
    );
    setcodingQuestions(newQuestions);
  };

  const handlecodingQuestionTypeChange = (index, field, event) => {
    if(event.target.value==='coding'){
      const newQuestions = codingquestions.map((q, qIndex) =>
        qIndex === index ? { ...q, [field]: false } : q
      );
      setcodingQuestions(newQuestions);
    }
    else{
      const newQuestions = codingquestions.map((q, qIndex) =>
        qIndex === index ? { ...q, [field]: true } : q
      );
      setcodingQuestions(newQuestions);
    }
  };




  const handleSubmit2 = (event) => {
    event.preventDefault();
    const formData = new FormData();
    codingquestions.forEach((option, index) => {
      formData.append(`question_text_${index}`, option.question_text);
      formData.append(`sample_input_${index}`, option.sample_input);
      formData.append(`sample_output_${index}`, option.sample_output);
      formData.append(`constraints_${index}`, option.constraints);
      formData.append(`explanation_${index}`, option.explanation);
      formData.append(`fillintheblank_${index}`, option.fillintheblank);
      if (option.imgforquestion) {
        formData.append(`imgforquestion_${index}`, option.imgforquestion);
      }
    });
    axios.post(`${ipaddress}/MultipleCodingQuestionCreateView/?module=${Number(module_id)}&category=${Number(category)}`,formData)
      .then((r) => {
        console.log("Coding Questions sent", r.data)
        setcategory('')
        setmodule_id('')
        setskill_id('')
        setcodingQuestions([{ question_text: '',
          sample_input: '',
          sample_output: '',
          constraints:'',
          explanation: '',
          imgforquestion:''
        }]);
        toast.success('Question Paper created', { autoClose: 2000,
          theme:'colored',
          position:'top-center'
         });
      })
      .catch((err) => {
        console.log("Coding Question Error", err);
      });
  };

  const [state, setstate] = useState('mcq');

  const[show,setshow]=useState(false)
  const[show2,setshow2]=useState(false)

  const[state1,setstate1]=useState(false)

  const[fillup,setfillup]=useState(false)

  return (
    <div className='d-flex'>
      <Sidebar activevalue={"create_questions"} />
      <div className="w-100 animate__animated animate__fadeIn">
        <Navbar />
        <div className="main-container">
          <div className='content-wrapper row m-0 d-flex' style={{ minHeight: '100vh' }}>
            <div className="scrollable col-lg-8 pb-4 py-3" style={{ maxHeight: '100vh', overflowY: 'auto' }}>
              <h6 className='fs-5 pt-2 pb-3 fw-medium text-dark'>Add New Questions</h6>
              <div className='d-flex gap-2 gap-md-5 pb-2'>
                <h5 onClick={() => { setstate('mcq') }} className='py-2 px-4 d-flex align-items-center pb-3 fs-6' style={{ color: state === 'mcq' ? '#57309C' : '', cursor: 'pointer', borderBottom: state === 'mcq' ? '2px solid #57309C' : 'none' }}>
                  <span className=''>MCQ Question</span></h5>
                <h5 onClick={() => { setstate('coding') }} className='py-2 px-4 d-flex align-items-center pb-3 fs-6' style={{ color: state === 'coding' ? '#57309C' : '', cursor: 'pointer', borderBottom: state === 'coding' ? '2px solid #57309C' : 'none' }}>
                  <span className=''>Coding Question</span></h5>
              </div>

              <div className='row m-0'>
                <div className="col-md-6 col-lg-4 ps-md-0">
                  <select name="" value={skill_id} onChange={(e)=>{
                    setskill_id(e.target.value)
                    fetch_modules(e.target.value)
                  }} className='form-select shadow-none border-secondary-subtle' id="">
                    <option value="">Choose Technical Skill...</option>
                    {skills.map((x)=>{
                      return(
                        <>
                        <option value={x.tech_id}>{x.name}</option>
                        </>
                      )
                    })}
                  </select>
                </div>

                <div className="col-md-6 col-lg-4">
                  <select name="" value={module_id} onChange={(e)=>{
                    setmodule_id(e.target.value)
                  }} className='form-select shadow-none border-secondary-subtle' id="">
                    <option value="">Choose Module...</option>
                    {modules.map((x)=>{
                      return(
                        <>
                        <option value={x.module_id}>{x.name}</option>
                        </>
                      )
                    })}
                  </select>
                </div>

                <div className="col-md-6 col-lg-4 pe-md-0">
                  <select name="" value={category} onChange={(e)=>{
                    setcategory(e.target.value)
                  }} className='form-select shadow-none border-secondary-subtle' id="">
                    <option value="">Choose Category...</option>
                    {categories.map((x)=>{
                      return(
                        <>
                        <option value={x.id}>{x.name}</option>
                        </>
                      )
                    })}
                  </select>
                </div>

              </div>
              {/* MCQ QUESTIONS */}

              <div className={state==='mcq' ? 'mt-3':'d-none'}>
                <div className="row m-0 bg-white rounded p-3 shadow-sm">
                <div className='col-12 p-0 d-flex align-items-end'>
                      <textarea
                        className='add-option form-control bg-light shadow-none border-0 mt-2'
                        type="text"
                        placeholder='Type your question here...'
                        value={questions.question_text}
                        onChange={(e)=>{
                          handleQuestionChange('question_text',e.target.value)
                        }}
                        required
                      />

{/* Question Image */}
<OverlayTrigger
                    placement="top"
                    delay={{ show: 250, hide: 250 }}
                    overlay={renderTooltip("Add Image")}
                  >
<label htmlFor={`question_img`} className='ms-3' style={{cursor:'pointer'}}>
<svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="gray" class="bi bi-image" viewBox="0 0 16 16">
  <path d="M6.002 5.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0"/>
  <path d="M2.002 1a2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V3a2 2 0 0 0-2-2zm12 1a1 1 0 0 1 1 1v6.5l-3.777-1.947a.5.5 0 0 0-.577.093l-3.71 3.71-2.66-1.772a.5.5 0 0 0-.63.062L1.002 12V3a1 1 0 0 1 1-1z"/>
</svg>
  <input onChange={handleQuestionImageChange} type="file" className='d-none' name="" id={`question_img`} />
</label>
</OverlayTrigger>
                    </div>

                    <div className="col-md-6 px-md-0 mt-3">
                      <label htmlFor="">Enter the Mark : </label>
                    <input type="number" style={{width:'80px'}} className='border rounded py-2 ms-3 px-3' onChange={(e)=>{
                        setq_marks(e.target.value)
                      }} value={q_marks} />
                    </div>

                    <div className="col-md-6 px-md-0 mt-3">
                      <label htmlFor="">Enter the Negative Mark : </label>
                    <input type="number" style={{width:'80px'}} className='border rounded py-2 ms-3 px-3' onChange={(e)=>{
                        setnegative_marks(e.target.value)
                      }} value={negative_marks} />
                    </div>

                    <div className="col-12 px-md-0 mt-3">
                    {questions.imgforquestion && (
  <img src={URL.createObjectURL(questions.imgforquestion)} className='mt-2' style={{width:'80px'}} alt="Question" />
  
)}

<div className='text-end mt-3'>
<button style={{fontSize:'15px'}} className={`btns btn btn-primary px-4 py-1 ${category && module_id ? 'd-none':''}`} onClick={()=>{
                toast.warn('Select Module and Category',{
                  autoClose:2000,
                  theme:'colored',
                  position:'top-center'
                })
              }}>Submit Question</button>
<button style={{fontSize:'15px'}} className={`btn btns btn-primary px-4 py-1 ${category && module_id ? '':'d-none'}`} onClick={()=>{
  add_question()
}}>Submit Question</button>
</div>
                    </div>
                </div>

                <form onSubmit={handleSubmit} className={`${state1 ? 'mt-3' : 'd-none'}`}>
                {/* {questions.map((q, qIndex) => ( */}
                  <div className='row m-0 mb-4 bg-white border-0 shadow-sm py-3 px-3' style={{ marginBottom: '20px', borderRadius: '10px' }}>
                    {options.map((opt, optIndex) => (
                      <div className='col-12 mt-2 border-bottom p-0 pb-3' key={optIndex}>
                       <div className='d-flex align-items-end'>
                       <input
                          placeholder={`Option ${optIndex + 1}`}
                          className='inputs add-option py-2 form-control shadow-none border-secondary-subtle border-bottom border-0 rounded-0 mt-2'
                          type="text"
                          value={opt.option_text}
                          onChange={(e) => handleInputChange(optIndex, 'option_text', e.target.value)}
                          required
                        />
                        <OverlayTrigger
                    placement="top"
                    delay={{ show: 250, hide: 250 }}
                    overlay={renderTooltip("Add Image")}
                  >
<label htmlFor={`option_img+${optIndex}`} className='ms-3' style={{cursor:'pointer'}}>
<svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="gray" class="bi bi-image" viewBox="0 0 16 16">
  <path d="M6.002 5.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0"/>
  <path d="M2.002 1a2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V3a2 2 0 0 0-2-2zm12 1a1 1 0 0 1 1 1v6.5l-3.777-1.947a.5.5 0 0 0-.577.093l-3.71 3.71-2.66-1.772a.5.5 0 0 0-.63.062L1.002 12V3a1 1 0 0 1 1-1z"/>
</svg>
  <input onChange={(e)=>{
    handleImageUpload(optIndex,e)
  }} type="file" className='d-none' name="" id={`option_img+${optIndex}`} />
</label>
</OverlayTrigger>
                       </div>
                       {options[optIndex].imgforoption && (
  <img src={URL.createObjectURL(options[optIndex].imgforoption)} className='mt-2' style={{width:'80px'}} alt="Question" />
)}

                        <input
                          className='inputs form-control shadow-none border-secondary-subtle border-bottom border-0 rounded-0 mt-3'
                          placeholder='Description'
                          value={opt.description}
                          onChange={(e) => handleInputChange( optIndex, 'description', e.target.value)}
                        />

                        <div className="row m-0">
                          <div className="col-md-6 ps-md-0">
                          <input
                          placeholder="Enter Mark here..."
                          className='inputs add-option py-2 form-control shadow-none border-secondary-subtle border-bottom border-0 rounded-0 mt-3'
                          type="number"
                          value={opt.marks}
                          onChange={(e) => handleInputChange(optIndex, 'marks', e.target.value)}
                          required
                        />
                          </div>
                          {/* <div className="col-md-6 pe-md-0">
                          <input
                          placeholder="Enter Negative Mark here..."
                          className='inputs add-option py-2 form-control shadow-none border-secondary-subtle border-bottom border-0 rounded-0 mt-3'
                          type="number"
                          value={opt.marks}
                          onChange={(e) => handleInputChange( optIndex, 'marks', e.target.value)}
                          required
                        />
                          </div> */}
                        </div>

                        <div className='mt-3'>
                        <input
                          type="checkbox"
                          className='me-2'
                          checked={opt.is_correct}
                          onChange={(e) => handleInputChange(optIndex, 'is_correct', e)}
                        /><label className='inputs'>Is Correct</label>
                        </div>

                       <div className="text-end mt-2">
                        <svg style={{cursor:'pointer'}} onClick={() => handleRemoveOption(optIndex)} xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="red" class="bi bi-trash" viewBox="0 0 16 16">
  <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0z"/>
  <path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4zM2.5 3h11V2h-11z"/>
</svg>
                       </div>
                      </div>
                    ))}
                    <div className='col-12 mt-3 text-end'>
                      <button
                        type="button"
                        className='btn btn-sm btn-primary'
                        onClick={() => handleAddOption()}
                      >
                        Add Option
                      </button>
                    </div>
                  </div>
                {/* ))} */}
                <div className='d-flex justify-content-end'>
                  <button type="submit" className='btn btn-sm text-white ms-3 px-4 btn-primary py-2'>Submit</button>
                </div>
              </form>
              </div>


              {/* CODING QUESTIONS */}
              <form onSubmit={handleSubmit2} className={`${state === 'coding' ? 'mt-3' : 'd-none'}`}>
                {codingquestions.map((q, qIndex) => (
                  <div className='row m-0 mb-4 bg-white border-0 shadow-sm py-3 px-3' key={qIndex} style={{ marginBottom: '20px', borderRadius: '10px' }}>
                    <div className='col-12 p-0'>
                      {/* <div className='d-flex align-items-center justify-content-evenly'>
                        <p className='d-flex align-items-center' style={{cursor:'pointer'}} onClick={()=>{
                          setfillup(false)
                        }}><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class={`bi bi-check-circle ${fillup ? '':'d-none'}`} viewBox="0 0 16 16">
  <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"/>
  <path d="m10.97 4.97-.02.022-3.473 4.425-2.093-2.094a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05"/>
</svg>

<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#0D6EFD" class={`bi bi-check-circle-fill ${fillup ? 'd-none':''}`} viewBox="0 0 16 16">
  <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
</svg>

<span className='ms-2'>Coding</span>
</p>

<p className='d-flex align-items-center' style={{cursor:'pointer'}} onClick={()=>{
                          setfillup(true)
                        }}><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class={`bi bi-check-circle ${fillup ? 'd-none':''}`} viewBox="0 0 16 16">
  <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"/>
  <path d="m10.97 4.97-.02.022-3.473 4.425-2.093-2.094a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05"/>
</svg>

<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#0D6EFD" class={`bi bi-check-circle-fill ${fillup ? '':'d-none'}`} viewBox="0 0 16 16">
  <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
</svg>

<span className='ms-2'>Fillup</span>
</p>
                      </div> */}

                      <div className='d-flex align-items-center mb-2'>
                        <label htmlFor="" className='fw-medium me-3'>Question Type : </label>
                        <select className='border-secondary-subtle rounded py-1 mb-1 px-3' name="" id="" value={codingquestions[qIndex].fillintheblank ? 'fillup' : 'coding'} onChange={(e)=>{
                        handlecodingQuestionTypeChange(qIndex,'fillintheblank',e)
                      }}>
                        <option value="coding">Coding</option>
                        <option value="fillup">Fillup</option>
                      </select>
                      </div>

                     <div className='d-flex mb-2 align-items-end'>
                     <textarea
                        className='add-option form-control bg-light shadow-none border-0 mt-2'
                        type="text"
                        placeholder='Type your coding question here...'
                        value={q.question_text}
                        onChange={(e) => handlecodingQuestionChange(qIndex, 'question_text', e)}
                        required
                      />
                      <OverlayTrigger
                    placement="top"
                    delay={{ show: 250, hide: 250 }}
                    overlay={renderTooltip("Add Image")}
                  >
<label htmlFor={`coding_img+${qIndex}`} className='ms-3' style={{cursor:'pointer'}}>
<svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="gray" class="bi bi-image" viewBox="0 0 16 16">
  <path d="M6.002 5.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0"/>
  <path d="M2.002 1a2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V3a2 2 0 0 0-2-2zm12 1a1 1 0 0 1 1 1v6.5l-3.777-1.947a.5.5 0 0 0-.577.093l-3.71 3.71-2.66-1.772a.5.5 0 0 0-.63.062L1.002 12V3a1 1 0 0 1 1-1z"/>
</svg>
  <input onChange={(e)=>{
    handleCodingImageUpload(qIndex,e)
  }} type="file" className='d-none' name="" id={`coding_img+${qIndex}`} />
</label>
</OverlayTrigger>
                     </div>
                     {codingquestions[qIndex].imgforquestion && (
  <img src={URL.createObjectURL(codingquestions[qIndex].imgforquestion)} className='my-2' style={{width:'80px'}} alt="Question" />
)}

                      
                    <div className={codingquestions[qIndex].fillintheblank ? 'd-none':''}>
                    <textarea
                        className='add-option form-control bg-light shadow-none border-0 mt-2'
                        placeholder='Input'
                        value={q.sample_input}
                        onChange={(e) => handlecodingQuestionChange(qIndex, 'sample_input', e)}
                        
                      />
                      <textarea
                        className='add-option form-control bg-light shadow-none border-0 mt-2'
                        placeholder='Output'
                        value={q.sample_output}
                        onChange={(e) => handlecodingQuestionChange(qIndex, 'sample_output', e)}
                        
                      />
                       <textarea
                        className='add-option form-control bg-light shadow-none border-0 mt-2'
                        placeholder='Constraints'
                        value={q.constraints}
                        onChange={(e) => handlecodingQuestionChange(qIndex, 'constraints', e)}
                        
                      />
                      <textarea
                        className='add-option form-control bg-light shadow-none border-0 mt-2 mb-2'
                        placeholder='Explanation'
                        value={q.explanation}
                        onChange={(e) => handlecodingQuestionChange(qIndex, 'explanation', e)}
                        
                      />
                    </div>
                      
                    </div>
                  </div>
                ))}
                <div className='d-flex justify-content-end'>
                  <OverlayTrigger
                    placement="top"
                    delay={{ show: 250, hide: 250 }}
                    overlay={renderTooltip3}
                  >
                    <button type="button" className='rounded-circle btn btn-sm bg-light text-white d-flex align-items-center' onClick={handleAddcodingQuestion}>
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="black" className="bi bi-plus-lg" viewBox="0 0 16 16">
                        <path fillRule="evenodd" d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2" />
                      </svg>
                    </button>
                  </OverlayTrigger>
                  <button style={{fontSize:'15px'}} className={`btn btns btn-primary px-4 py-1 ms-3 ${category && module_id ? 'd-none':''}`} onClick={()=>{
                toast.warn('Select Module and Category',{
                  autoClose:2000,
                  theme:'colored',
                  position:'top-center',
                })
              }}>Submit Question</button>
                  <button type="submit" className={`btn btn-primary px-4 py-1 ms-3 ${category && module_id ? '':'d-none'}`} style={{ backgroundColor: '#57309C' }}>Submit Question</button>
                </div>
              </form>

            </div>

            <div className="col-md-3 create-question-div pt-4 text-end" style={{position:'fixed',right:0,height:'100vh'}}>
            <div className={state==='mcq' ? '':'d-none'}>
            <button className={`btn btn-sm btn-light py-2 fw-medium px-4 ${category && module_id ? 'd-none':''}`} style={{borderRadius:'15px 15px 15px 0px'}} onClick={()=>{
                toast.warn('Select Module and Category',{
                  autoClose:2000,
                  theme:'colored',
                  position:'top-center'
                })
              }}>Upload Excel</button>
              <button className={`btn btn-sm btn-light fw-medium py-2 px-4 ${category && module_id ? '':'d-none'}`} style={{borderRadius:'15px 15px 15px 0px'}} onClick={()=>{
                setshow(true)
              }}>Upload Excel</button>
            </div>

            <div className={state==='coding' ? '':'d-none'}>
            <button className={`btn btn-sm btn-light fw-medium py-2 px-4 ${category && module_id ? 'd-none':''}`} style={{borderRadius:'15px 15px 15px 0px'}} onClick={()=>{
                toast.warn('Select Module and Category',{
                  autoClose:2000,
                  theme:'colored',
                  position:'top-center'
                })
              }}>Upload Excel</button>
              <button className={`btn btn-sm btn-light py-2 fw-medium px-4 ${category && module_id ? '':'d-none'}`} style={{borderRadius:'15px 15px 15px 0px'}} onClick={()=>{
                setshow2(true)
              }}>Upload Excel</button>
            </div>
            </div>
          </div>
        </div>
      </div>

      <Mcq_excel show={show} setshow={setshow} setcategory={setcategory} setskill_id={setskill_id} setmodule_id={setmodule_id} module_id={module_id} category={category}/>
      <Coding_excel show={show2} setshow={setshow2} setcategory={setcategory} setskill_id={setskill_id} setmodule_id={setmodule_id} module_id={module_id} category={category}/>
      
    </div>
  );
};

export default Create_questions;
