import React, { useEffect, useState } from 'react'
import Sidebar from './Sidebar'
import Navbar from './Navbar'
import axios from 'axios'
import { ipaddress } from '../App'
import { useNavigate } from 'react-router-dom'
import Backtotop from '../Pages/Backtotop'

const Students = () => {
    const[students,setstudents]=useState([])

    useEffect(()=>{
       fetch_students()
    },[])

    const fetch_students=()=>{
        axios.get(`${ipaddress}/student/details/`)
        .then((r)=>{
            console.log("students",r.data);
            const date=new Date()
            console.log(date)
            setstudents(r.data)
        })
    }

    const navigate=useNavigate()

    const search_student=(value)=>{
        if(value.length>0){
            axios.get(`${ipaddress}/student/details/?student_name=${value}`)
            .then((r)=>{
                console.log("Searched students",r.data);
                setstudents(r.data)
            })
        }
        else{
            fetch_students()
        }
    }


  return (
    <div className='d-flex'>
      <Sidebar activevalue={"students"}/>
      <div className="w-100 animate__animated animate__fadeIn">
        <Navbar/>
        <div className="main-container py-4 pe-md-4 ps-md-2">
            <div className='d-flex align-items-center justify-content-between'>
            <h5 className='mb-4'>Students List</h5>
           <div>
           <div class="input-group mb-3">
  <input type="text" onChange={(e)=>{
    search_student(e.target.value)
  }} class="form-control shadow-none border-secondary-subtle" placeholder="Search..." aria-label="Recipient's username" aria-describedby="basic-addon2"/>
  <span class="input-group-text" id="basic-addon2"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-search" viewBox="0 0 16 16">
  <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001q.044.06.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1 1 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0"/>
</svg></span>
</div>
           </div>
            </div>
            <div className="table-responsive">
            <table class="table shadow-sm">
  <thead>
    <tr>
      <th scope="col" className='border-0'><p className='text-center m-0 py-2'>SI.No</p></th>
      <th scope="col" className='border-0'><p className='text-center m-0 py-2'>Name</p></th>
      <th scope="col" className='border-0'><p className='text-center m-0 py-2'>Email</p></th>
      <th scope="col" className='border-0'><p className='text-center m-0 py-2'>Contact Number</p></th>
    </tr>
  </thead>
  <tbody>
    {students.map((x,index)=>{
        return(
            <tr className={index%2==0 ? 'table-light':''}>
      <th scope="row" className='border-0'><p className='text-center m-0 py-2'>{index+1}</p></th>
      <td className='border-0'><p className='text-center m-0 text-primary fw-medium  py-2' style={{cursor:'pointer'}} onClick={()=>{
        navigate(`/view_student/${x.id}`)
      }}>{x.username}</p></td>
      <td className='border-0'><p className='text-center m-0 py-2'>{x.email}</p></td>
      <td className='border-0'><p className='text-center m-0 py-2'>{x.contact}</p></td>
    </tr>
        )
    })}
  </tbody>
</table>
            </div>
        </div>
      </div>

      <Backtotop/>
    </div>
  )
}

export default Students
